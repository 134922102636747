import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components'
import { useAccount } from 'wagmi'
import { useCallback, useEffect, useMemo } from 'react'
import { watchAccount } from 'wagmi/actions'
import { useQueryClient } from '@tanstack/react-query'
import ArrowDown from '@/components/Icons/ArrowDown'
import Wallet from '@/components/Icons/Wallet'
import MaterialSymbolsLogout from '@/components/Icons/MaterialSymbolsLogout'
import { disconnectWallet } from '@/api'
import { ButtonBase } from '@/components/Button'
import { useUserInfo } from '@/api/get-user'
import { TokenEth } from '@/components/Icons/TokenEth'
import TokenBlast from '@/components/Icons/TokenBlast'
import wagmiConfig from '@/constants/wagmiConfig'
import { getAuthObject } from '@/utils/authObject'
import WalletModal, { BTCWalletModal } from '../WalletModal'
import { shortenAddress } from '../../utils'
import { useWalletModalToggle } from '../../state/application/hooks'
import type { GetAccountReturnType } from 'wagmi/actions'

const chainLogos = new Map([
  [1, TokenEth],
  [81457, TokenBlast],
  [168587773, TokenBlast],
])

function Web3StatusInner() {
  const { isConnecting, chainId, address } = useAccount()
  const { data: userInfo } = useUserInfo()
  const toggleWalletModal = useWalletModalToggle()
  const queryClient = useQueryClient()

  const handleDisconnect = () => {
    disconnectWallet()
  }

  const ChainLogo = useMemo(() => (chainId ? chainLogos.get(chainId) : undefined), [chainId])

  const handleChange = useCallback(
    (account: GetAccountReturnType, prevAccount: GetAccountReturnType) => {
      // disconnectted
      if (!account.address && prevAccount.address) {
        disconnectWallet()
        return
      }

      // switched account
      if (account.address && prevAccount.address && account.address !== prevAccount.address) {
        const authObject = getAuthObject()
        console.log('authObject[account.address]?.authorization', authObject[account.address]?.authorization)
        if (authObject[account.address]?.authorization) {
          queryClient.invalidateQueries()
          return
        }
        queryClient.setQueryData(['get-current-login-user'], undefined)
        return
      }
    },
    [queryClient],
  )

  useEffect(() => {
    return watchAccount(wagmiConfig, {
      onChange: handleChange,
    })
  }, [handleChange])

  if (userInfo && address) {
    return (
      <MenuTrigger>
        <Button className={'flex items-center text-xs h-9 px-2 rounded gap-2 bg-[#192129]'}>
          {ChainLogo && <ChainLogo className={'text-[20px]'} />}
          <span>{shortenAddress(address)}</span>
          <ArrowDown className={'text-lemonYellow text-xl'} />
        </Button>

        <Popover className={'outline-none'}>
          <Menu className={'rounded bg-[#192129] px-2 outline-none'}>
            <MenuItem
              onAction={handleDisconnect}
              className={'flex items-center gap-1 text-xs h-9 text-[#A5A5A5] cursor-pointer outline-none'}
            >
              <span className={'mr-2 text-2xl'}>
                <MaterialSymbolsLogout />
              </span>
              <span>{'Disconnect'}</span>
            </MenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    )
  }

  return (
    <ButtonBase
      onPress={toggleWalletModal}
      className={'border border-aaa/50 gap-2'}
      loadingClassName={'!text-aaa/50'}
      isLoading={isConnecting}
    >
      <Wallet className={'text-xl'} />
      <span>{'Connect Wallet'}</span>
    </ButtonBase>
  )
}

export default function Web3Status() {
  return (
    <>
      <Web3StatusInner />
      <WalletModal />
      <BTCWalletModal />
    </>
  )
}
